import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UsersSelectors, UsersActions } from '../redux';
import { UsersApi } from '../api';
import { User } from '../types';

export const useGetUsers = (): {
  users: User[];
  error: boolean;
  loading: boolean;
  fetchUsers: () => Promise<void>;
  hasMore: boolean;
} => {
  const dispatch = useDispatch();
  const users = useSelector(UsersSelectors.list);
  const hasMore = useSelector(UsersSelectors.hasMore);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchUsers = useCallback(async () => {
    try {
      if (!hasMore) {
        return;
      }
      setLoading(true);
      const responseUsers = await UsersApi.getUsers();
      dispatch(UsersActions.setUsers(responseUsers));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [hasMore]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return {
    users,
    error,
    loading,
    fetchUsers,
    hasMore,
  };
};
