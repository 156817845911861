import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { AuthActions } from '../redux';
import { AuthApi } from '../api';

export const useLogInAdmin = (): {
  error: string;
  handleLogin: (values: { email: string; password: string }) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const handleLogin = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const tokens = await AuthApi.login({ password, email });
        const { claims } = jwtDecode<{ claims: string[] | undefined }>(
          tokens.accessToken,
        );
        if (!claims?.includes('admin')) {
          setError("Email and password doesn't match");
          return;
        }
        dispatch(AuthActions.logInUser(tokens));
      } catch (error) {
        setError("Email and password doesn't match");
      }
    },
    [],
  );
  return { error, handleLogin };
};
