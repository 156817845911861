import { useState, useCallback } from 'react';

import { BoatsApi } from '../api';

export const useDeleteBoat = (
  onSuccess: () => void,
): {
  error: boolean;
  loading: boolean;
  deleteBoat: (boatId: number) => Promise<void>;
} => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const deleteBoat = useCallback(async (boatId: number) => {
    try {
      setLoading(true);
      await BoatsApi.deleteBoat(boatId);
      onSuccess();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    error,
    loading,
    deleteBoat,
  };
};
