import React, { memo } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { Routes } from 'Routes';

const SideMenu: React.FC = () => {
  const location = useLocation();

  return (
    <Menu
      selectedKeys={[location.pathname]}
      mode="inline"
      style={{ width: 256, height: '100vh', paddingTop: 64 }}
    >
      <Menu.Item key={Routes.Root}>
        <span>Users</span>
        <Link to={Routes.Root} />
      </Menu.Item>
      <Menu.Item key={Routes.Logs}>
        <span>Logs</span>
        <Link to={Routes.Logs} />
      </Menu.Item>
    </Menu>
  );
};

export default memo(SideMenu);
