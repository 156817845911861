import React, { memo } from 'react';
import { Select } from 'antd';
import { useGetUsers } from 'users/hooks';

const { Option } = Select;

type Props = {
  onChange: (value: number) => void;
};

const UsersSelectUi: React.FC<Props> = ({ onChange }) => {
  const { users, loading } = useGetUsers();
  return (
    <Select
      showSearch
      size="large"
      allowClear
      loading={loading}
      style={{ width: 500, marginBottom: 16 }}
      placeholder="Select a user"
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        option && option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {users.map((user) => (
        <Option value={user.id} key={user.id}>
          {`${user.name} - ${user.email}`}
        </Option>
      ))}
    </Select>
  );
};

export const UsersSelect = memo(UsersSelectUi);
