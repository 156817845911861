/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import InputLabel from '../InputLabel';

import {
  Container,
  UploadedFileContainer,
  UploadFileContainer,
} from './styledComponents';

type Props = {
  onChange: (file: File) => void;
  label: string;
  file: File | null;
};

const DragAndDrop: React.FC<Props> = ({ onChange, label, file }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange(acceptedFiles && acceptedFiles[0]);
    },
    [onChange],
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
  });

  const replaceHandler = useCallback(
    (e: React.MouseEvent<HTMLElement> | undefined) => {
      if (e) {
        e.stopPropagation();
      }
      open();
    },
    [open],
  );

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Container {...getRootProps()}>
        <input
          {...getInputProps()}
          accept=".csv, .pol, .tcs, .xls, .xsls, .xml, .text"
        />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : !file ? (
          <UploadFileContainer>
            <UploadOutlined />
            <p>Drag & drop your file here, or choose a file</p>
          </UploadFileContainer>
        ) : (
          <UploadedFileContainer>
            <CheckCircleOutlined />
            <div>
              {file.name}
              {new Date(file.lastModified).toDateString()}
            </div>
            <Button onClick={replaceHandler}>Replace</Button>
          </UploadedFileContainer>
        )}
      </Container>
    </>
  );
};

export default React.memo(DragAndDrop);
