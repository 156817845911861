import { createAction } from '@reduxjs/toolkit';
import { User } from '../types';

export const setUsers = createAction<User[]>('SET_USERS');
export const setUser = createAction<User>('SET_USER');

export const UsersActions = {
  setUsers,
  setUser,
};
