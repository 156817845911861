import { useCallback, useState } from 'react';

export const useModal = (
  initialIsOpen = false,
): [isOpen: boolean, openModal: () => void, closeModal: () => void] => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return [isOpen, openModal, closeModal];
};
