import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from 'Routes';

import { LogsListPage } from './pages';

export const LogsSwitch: React.FC = memo(() => {
  return (
    <Switch>
      <Route path={Routes.Logs} component={LogsListPage} />
      <Route component={LogsListPage} />
    </Switch>
  );
});
