import { useState, useCallback, useEffect } from 'react';

import { LogsApi } from '../api';
import { Log } from '../types';

export const useGetLogs = (
  userId: number | undefined,
): {
  logs: Log[];
  error: boolean;
  loading: boolean;
} => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchLogs = useCallback(async (userId: number | undefined) => {
    try {
      setLoading(true);
      const responseLogs = await LogsApi.getLogs({ userId });
      setLogs(responseLogs);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLogs(userId);
  }, [userId]);

  return {
    logs,
    error,
    loading,
  };
};
