import { useState, useCallback } from 'react';
import { message } from 'antd';

import { UsersApi } from '../api';
import { User } from '../types';

export const useChangeUserPassword = (
  onSuccess: () => void,
): {
  error: boolean;
  loading: boolean;
  updateUsersPassword: (user: {
    id: User['id'];
    password: string;
  }) => Promise<void>;
} => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateUsersPassword = useCallback(
    async ({ id, password }: { id: User['id']; password: string }) => {
      try {
        if (!id) throw new Error('No user id');
        setLoading(true);
        await UsersApi.changeUserPassword({
          userId: id,
          password,
        });
        onSuccess();
        message.success('User password updated');
      } catch (error) {
        setError(true);
        message.error('Error while updating user password');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    error,
    loading,
    updateUsersPassword,
  };
};
