import { useCallback, useState } from 'react';
import { message } from 'antd';
import { useDispatch } from 'react-redux';

import { UsersApi } from '../api';
import { UsersActions } from '../redux';

export const useChangeUserStatus = (
  id: number | undefined,
): {
  approveUser: () => Promise<void>;
  freezeUser: () => Promise<void>;
  loading: boolean;
} => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const approveUser = useCallback(async () => {
    try {
      if (!id) return;

      setLoading(true);
      const updatedUser = await UsersApi.approveUser(id);
      dispatch(UsersActions.setUser(updatedUser));
      message.success('User approved');
    } catch (error) {
      message.error('Error while approving user');
    } finally {
      setLoading(false);
    }
  }, [id]);

  const freezeUser = useCallback(async () => {
    try {
      if (!id) return;

      setLoading(true);
      const updatedUser = await UsersApi.freezeUser(id);
      dispatch(UsersActions.setUser(updatedUser));
      message.success('User freezed');
    } catch (error) {
      message.error('Error while freezing user');
    } finally {
      setLoading(false);
    }
  }, [id]);

  return {
    approveUser,
    freezeUser,
    loading,
  };
};
