import React, { memo } from 'react';
import { Modal } from 'antd';

type Props = {
  closeModal: () => void;
  afterClose?: () => void;
  loading: boolean;
  isOpen: boolean;
  title: string;
  children: React.ReactChild | React.ReactNodeArray | null;
};

const NoFooterModalUi: React.FC<Props> = ({
  closeModal,
  isOpen,
  title,
  children,
  afterClose,
}) => (
  <Modal
    visible={isOpen}
    title={title}
    destroyOnClose
    onCancel={closeModal}
    footer={null}
    afterClose={afterClose}
  >
    {children}
  </Modal>
);

export const NoFooterModal = memo(NoFooterModalUi);
