import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import localforage from 'localforage';

import { logInUser, logOutUser, refreshTokens } from './actions';

const accessToken = createReducer('', (builder) => {
  builder
    .addCase(logInUser, (state, action) => action.payload.accessToken)
    .addCase(refreshTokens, (state, action) => action.payload.accessToken)
    .addCase(logOutUser, () => '');
});

const refreshToken = createReducer('', (builder) => {
  builder
    .addCase(logInUser, (state, action) => action.payload.refreshToken)
    .addCase(refreshTokens, (state, action) => action.payload.refreshToken)
    .addCase(logOutUser, () => '');
});

const reducer = combineReducers({
  accessToken,
  refreshToken,
});

const authReducerConfig: PersistConfig<ReturnType<typeof reducer>> = {
  key: 'auth',
  storage: localforage,
};

export const authReducer = persistReducer(authReducerConfig, reducer);
