import { apiClient } from 'apiClient';

type LoginApiBody = {
  email: string;
  password: string;
};

type LoginApiResponse = {
  accessToken: string;
  refreshToken: string;
};

type RefreshApiBody = {
  token: string;
};

type RefreshApiResponse = {
  accessToken: string;
  refreshToken: string;
};

const login = async (data: LoginApiBody): Promise<LoginApiResponse> => {
  const response = await apiClient.post('/v1/auth/login', data);
  return response.data;
};

const refreshToken = async (
  data: RefreshApiBody,
): Promise<RefreshApiResponse> => {
  const response = await apiClient.post('/v1/auth/refresh', data);
  return response.data;
};

export const AuthApi = { login, refreshToken };
