import React, { memo } from 'react';
import { Input, Space, Table, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { formatFullDate } from 'date';
import { AccountStatus, AccountStatusLabels } from '../../const';
import { User } from '../../types';

const { Column } = Table;

const formatDateRow = (text: string) => (
  <span>{text ? formatFullDate(text) : ''}</span>
);

const formatStatus = (status: AccountStatus) => (
  <span>{AccountStatusLabels[status]}</span>
);

const getColumnSearchProps = (dataIndex: keyof User) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm({ closeDropdown: true })}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value: string | number | boolean, record: User) =>
    record[dataIndex] && typeof value === 'string'
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : false,
});

type Props = {
  users: User[];
  loading: boolean;
};

export const UserTable: React.FC<Props> = memo(({ users, loading }) => {
  return (
    <Table dataSource={users} loading={loading} rowKey="id">
      <Column title="Id" dataIndex="id" key="id" />
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        {...getColumnSearchProps('name')}
      />
      <Column
        title="Email"
        dataIndex="email"
        key="email"
        {...getColumnSearchProps('email')}
      />
      <Column
        title="Last login"
        dataIndex="lastLogin"
        key="lastLogin"
        render={formatDateRow}
        sorter={(a: User, b: User) =>
          new Date(a.lastLogin).getTime() - new Date(b.lastLogin).getTime()
        }
      />
      <Column
        title="Created at"
        dataIndex="createdAt"
        key="createdAt"
        render={formatDateRow}
        sorter={(a: User, b: User) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        }
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        filters={[
          {
            text: AccountStatusLabels[AccountStatus.Init],
            value: AccountStatus.Init,
          },
          {
            text: AccountStatusLabels[AccountStatus.Approved],
            value: AccountStatus.Approved,
          },
          {
            text: AccountStatusLabels[AccountStatus.Freezed],
            value: AccountStatus.Freezed,
          },
        ]}
        onFilter={(value: string | number | boolean, record: User) =>
          record.status === value
        }
        render={formatStatus}
      />
      <Column
        title="Action"
        dataIndex="id"
        key="action"
        render={(value) => <Link to={`/users/${value}`}>Details</Link>}
      />
    </Table>
  );
});
