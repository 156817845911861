import { apiClient } from 'apiClient';

import { Log } from '../types';

const getLogs = async ({
  userId,
}: {
  userId: number | undefined;
}): Promise<Log[]> => {
  const response = await apiClient.get('/v1/logs', { params: { userId } });
  return response.data || [];
};

export const LogsApi = { getLogs };
