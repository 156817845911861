import React, { memo } from 'react';
import { Input, Space, Table, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { formatFullDate } from 'date';
import { Log } from '../../types';

const { Column } = Table;

const formatDateRow = (text: string) => (
  <span>{text ? formatFullDate(text) : ''}</span>
);

const getColumnSearchProps = (dataIndex: keyof Log) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm({ closeDropdown: true })}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value: string | number | boolean, record: Log) =>
    record[dataIndex] && typeof value === 'string'
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : false,
});

type Props = {
  logs: Log[];
  loading: boolean;
};

export const LogsTable: React.FC<Props> = memo(({ logs, loading }) => {
  return (
    <Table dataSource={logs} loading={loading} rowKey="id">
      <Column title="Id" dataIndex="id" key="id" />
      <Column
        title="Resource"
        dataIndex="resource"
        key="resource"
        {...getColumnSearchProps('resource')}
      />
      <Column
        title="Action"
        dataIndex="action"
        key="action"
        {...getColumnSearchProps('action')}
      />
      <Column
        title="Timestamp"
        dataIndex="createdAt"
        key="createdAt"
        render={formatDateRow}
      />
      <Column
        title="Parameters"
        dataIndex="params"
        key="params"
        width={300}
        ellipsis
        render={(value) =>
          value && (
            <Tooltip placement="topLeft" title={JSON.stringify(value)}>
              {JSON.stringify(value)}
            </Tooltip>
          )
        }
      />
      <Column title="User id" dataIndex="userId" key="userId" />
      <Column title="IP address" dataIndex="ipAddress" key="ipAddress" />
    </Table>
  );
});
