import { Button } from 'antd';
import { Field, Form } from 'react-final-form';
import React, { memo } from 'react';

import { FromTextInput } from 'ui';
import { validateRequiredString } from 'form';
import { NoFooterModal } from 'modal';
import { User } from '../../types';
import { useEditUser } from '../../hooks';

import { ButtonContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  user: User;
};

const EditUserModalUi: React.FC<Props> = ({ user, isOpen, closeModal }) => {
  const { loading, updateUser } = useEditUser(closeModal);
  return (
    <NoFooterModal
      isOpen={isOpen}
      closeModal={closeModal}
      title="Update user info"
      loading={loading}
    >
      <Form
        onSubmit={updateUser}
        initialValues={user}
        render={({ handleSubmit, invalid }): React.ReactNode => (
          <>
            <Field
              name="firstName"
              component={FromTextInput}
              label="First name"
              size="large"
              validate={validateRequiredString}
            />
            <Field
              name="lastName"
              component={FromTextInput}
              label="Last name"
              size="large"
              validate={validateRequiredString}
            />
            <Field
              name="about"
              component={FromTextInput}
              label="About"
              size="large"
              validate={validateRequiredString}
            />
            <Field
              name="teamOrCompany"
              component={FromTextInput}
              label="Team/Company"
              size="large"
              validate={validateRequiredString}
            />
            <ButtonContainer>
              <Button
                onClick={handleSubmit}
                disabled={invalid}
                type="primary"
                loading={loading}
                style={{ marginTop: 16 }}
              >
                Update
              </Button>
            </ButtonContainer>
          </>
        )}
      />
    </NoFooterModal>
  );
};

export const EditUserModal = memo(EditUserModalUi);
