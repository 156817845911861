import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from 'Routes';

import { UsersListPage, UserDetailsPage } from './pages';

export const UsersSwitch: React.FC = memo(() => {
  return (
    <Switch>
      <Route path={Routes.UserDetails} component={UserDetailsPage} />
      <Route component={UsersListPage} />
    </Switch>
  );
});
