import { apiClient } from 'apiClient';

import { generateUserObject } from '../service';

import { User } from '../types';

const getUsers = async (): Promise<User[]> => {
  const response = await apiClient.get('/v1/users');
  return (
    (response.data &&
      response.data.map((data: User) => generateUserObject(data))) ||
    []
  );
};

const getUser = async (id: number): Promise<User> => {
  const response = await apiClient.get(`/v1/users/${id}`);
  return generateUserObject(response.data);
};

const updateUser = async (user: Partial<User>): Promise<User> => {
  const response = await apiClient.patch(`/v1/users/${user.id}`, user);
  return generateUserObject(response.data);
};

const approveUser = async (id: number): Promise<User> => {
  const response = await apiClient.post(`/v1/users/${id}/approve`);
  return response.data;
};

const freezeUser = async (id: number): Promise<User> => {
  const response = await apiClient.post(`/v1/users/${id}/freeze`);
  return response.data;
};

const getUsersCsv = async (): Promise<Blob> => {
  const response = await apiClient.get('/v1/users/csv', {
    responseType: 'blob',
  });
  return response.data;
};

const changeUserPassword = async ({
  password,
  userId,
}: {
  password: string;
  userId: number;
}): Promise<void> => {
  await apiClient.patch(`/v1/users/${userId}/password`, { password });
};

export const UsersApi = {
  getUsers,
  getUser,
  approveUser,
  freezeUser,
  updateUser,
  getUsersCsv,
  changeUserPassword,
};
