import React, { memo, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import InputLabel from '../InputLabel';
import InputError from '../InputError';

import { Container } from './styledComponents';

type FormTextInputProps = {
  label?: string;
  type?: string;
  placeholder?: string;
  size?: SizeType;
  password?: boolean;
} & FieldRenderProps<number | string, HTMLInputElement>;

const FromTextInputUi: React.FC<FormTextInputProps> = ({
  label,
  input,
  type,
  placeholder,
  size,
  password,
  meta: { invalid, touched, error },
}) => (
  <Container>
    {label && <InputLabel invalid={!!(invalid && touched)}>{label}</InputLabel>}
    {!password && (
      <Input placeholder={placeholder} size={size} type={type} {...input} />
    )}
    {password && (
      <Input.Password
        placeholder={placeholder}
        size={size}
        type={type}
        {...input}
      />
    )}
    {touched && error && <InputError>{error}</InputError>}
  </Container>
);

type TextInputProps = {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  size?: SizeType;
  type?: string;
  placeholder?: string;
};

const TextInputUi: React.FC<TextInputProps> = ({
  label,
  value,
  onChange,
  error,
  size,
  type,
  placeholder,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );
  return (
    <Container>
      {label && <InputLabel invalid={!!error}>{label}</InputLabel>}
      <Input
        placeholder={placeholder}
        size={size}
        type={type}
        onChange={handleChange}
        value={value}
      />
      {error && <InputError>{error}</InputError>}
    </Container>
  );
};

export const FromTextInput = memo(FromTextInputUi);
export const TextInput = memo(TextInputUi);
