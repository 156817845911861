import React, { memo, useState } from 'react';

import { UsersSelect } from 'users';
import { useGetLogs } from '../../hooks';
import { LogsTable } from '../../components';

const LogsListPageUi: React.FC = () => {
  const [userId, setUserId] = useState<number | undefined>();
  const { logs, loading } = useGetLogs(userId);
  return (
    <>
      <UsersSelect onChange={setUserId} />
      <LogsTable logs={logs} loading={loading} />
    </>
  );
};

export const LogsListPage = memo(LogsListPageUi);
