import { Button } from 'antd';
import { Field, Form } from 'react-final-form';
import React, { memo } from 'react';

import { FromTextInput } from 'ui';
import { validateNewPassword } from 'form';
import { NoFooterModal } from 'modal';
import { User } from '../../types';
import { useChangeUserPassword } from '../../hooks';

import { ButtonContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  user: User;
};

const ChangePasswordModalUi: React.FC<Props> = ({
  user,
  isOpen,
  closeModal,
}) => {
  const { loading, updateUsersPassword } = useChangeUserPassword(closeModal);
  return (
    <NoFooterModal
      isOpen={isOpen}
      closeModal={closeModal}
      title="Update user password"
      loading={loading}
    >
      <Form
        onSubmit={updateUsersPassword}
        initialValues={user}
        render={({ handleSubmit, invalid }): React.ReactNode => (
          <>
            <Field
              name="password"
              component={FromTextInput}
              label="New password"
              size="large"
              validate={validateNewPassword}
            />
            <ButtonContainer>
              <Button
                onClick={handleSubmit}
                disabled={invalid}
                type="primary"
                loading={loading}
                style={{ marginTop: 16 }}
              >
                Update password
              </Button>
            </ButtonContainer>
          </>
        )}
      />
    </NoFooterModal>
  );
};

export const ChangePasswordModal = memo(ChangePasswordModalUi);
