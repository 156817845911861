import { apiClient } from 'apiClient';
import { Boat } from 'boats/types';

const getBoats = async (id: number): Promise<Boat[]> => {
  const response = await apiClient.get(`/v1/users/${id}/boats`);
  return response.data;
};

type UpdateBoatFileProps = {
  id: number;
  name: string;
  files: {
    polars: File | null;
    sails: File | null;
    wave: File | null;
  };
};

const updateBoatFiles = async ({
  id,
  files,
  name,
}: UpdateBoatFileProps): Promise<void> => {
  const formData = new FormData();
  formData.append('name', name);
  if (files.polars) {
    formData.append('polars', files.polars);
  }
  if (files.sails) {
    formData.append('sails', files.sails);
  }
  if (files.wave) {
    formData.append('wave', files.wave);
  }
  await apiClient.patch(`/v1/boats/${id}`, formData);
};

type CreateBoatProps = {
  userId: number;
  name: string;
  files: {
    polars: File | null;
    sails: File | null;
    wave: File | null;
  };
};

const createBoat = async ({
  userId,
  files,
  name,
}: CreateBoatProps): Promise<void> => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('userId', userId.toString());
  if (files.polars) {
    formData.append('polars', files.polars);
  }
  if (files.sails) {
    formData.append('sails', files.sails);
  }
  if (files.wave) {
    formData.append('wave', files.wave);
  }
  await apiClient.post(`/v1/boats/`, formData);
};

const deleteBoat = async (id: number): Promise<void> => {
  const response = await apiClient.delete(`/v1/boats/${id}`);
  return response.data;
};

export const BoatsApi = { getBoats, updateBoatFiles, createBoat, deleteBoat };
