import React, { memo } from 'react';
import { Button } from 'antd';
import { Field, Form } from 'react-final-form';

import { FromTextInput } from 'ui';
import { validateRequiredString } from 'form';
import { useLogInAdmin } from '../../hooks';
import { Container, Content } from './styled';

const LoginPageUi: React.FC = () => {
  const { handleLogin } = useLogInAdmin();
  return (
    <Container>
      <Content>
        <Form
          onSubmit={handleLogin}
          render={({ handleSubmit, invalid }): React.ReactNode => (
            <>
              <Field
                name="email"
                component={FromTextInput}
                label="Email"
                placeholder="Email"
                size="large"
                validate={validateRequiredString}
              />
              <Field
                name="password"
                component={FromTextInput}
                label="Password"
                placeholder="Password"
                size="large"
                password
                validate={validateRequiredString}
              />
              <Button
                style={{ marginTop: 16 }}
                type="primary"
                shape="round"
                size="large"
                onClick={handleSubmit}
                disabled={invalid}
              >
                Log in
              </Button>
            </>
          )}
        />
      </Content>
    </Container>
  );
};

export const LoginPage = memo(LoginPageUi);
