import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { User } from '../types';

const storage = (state: RootState): { [key: number]: User } =>
  state.users.storage;

const hasMore = (state: RootState): boolean => state.users.hasMore;

const list = createSelector([storage], (usersMap) => {
  return Object.values(usersMap);
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const userByIdSelectorFactory = (userId: number | undefined) => {
  return createSelector([storage], (usersMap) =>
    userId ? usersMap[userId] : undefined,
  );
};

export const UsersSelectors = {
  storage,
  hasMore,
  list,
  userByIdSelectorFactory,
};
