import { Empty, Progress, Descriptions, Button, Popconfirm } from 'antd';
import { formatFullDate } from 'date';
import React, { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetUserBoats,
  EditBoatModal,
  CreatBoatModal,
  useDeleteBoat,
} from 'boats';
import { Boat } from 'boats/types';
import { useModal } from 'modal';
import { DownloadLink } from 'file';
import { AccountStatus, AccountStatusLabels } from '../../const';
import { ChangePasswordModal, EditUserModal } from '../../components';
import { useGetUser, useChangeUserStatus } from '../../hooks';

import { Container } from './styledComponents';

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [editedBoat, setEditedBoat] = useState<Boat | undefined>();
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [
    isCreateBoatModalOpen,
    openCreateBoatModal,
    closeCreateBoatModal,
  ] = useModal(false);
  const [
    isChangePasswordModalOpen,
    openChangePasswordModal,
    closeChangePasswordModal,
  ] = useModal(false);
  const [isEditUserModalOpen, openEditUserModal, closeEditUserModal] = useModal(
    false,
  );
  const { user, loading: userLoading } = useGetUser(Number(id));
  const { boats, loading: boatLoading, fetchBoats } = useGetUserBoats(
    Number(id),
  );
  const { deleteBoat } = useDeleteBoat(fetchBoats);

  const deleteSelectedBoat = useCallback(async () => {
    if (!deleteId) return;

    await deleteBoat(deleteId);
  }, [deleteId]);

  const {
    approveUser,
    loading: userApproveLoading,
    freezeUser,
  } = useChangeUserStatus(user?.id);

  const closeModal = useCallback(() => {
    setEditedBoat(undefined);
  }, []);

  const onBoatUpdate = useCallback(() => {
    closeModal();
    fetchBoats();
  }, [fetchBoats, closeModal]);

  const onBoatCrated = useCallback(() => {
    closeCreateBoatModal();
    fetchBoats();
  }, [fetchBoats, closeModal]);

  if (userLoading || boatLoading) {
    return <Progress />;
  }

  if (!user) return <Empty />;

  return (
    <>
      {editedBoat && (
        <EditBoatModal
          closeModal={closeModal}
          isOpen={!!editedBoat}
          boat={editedBoat}
          onSuccess={onBoatUpdate}
        />
      )}
      <CreatBoatModal
        isOpen={isCreateBoatModalOpen}
        closeModal={closeCreateBoatModal}
        onSuccess={onBoatCrated}
        userId={user.id}
      />
      <EditUserModal
        isOpen={isEditUserModalOpen}
        closeModal={closeEditUserModal}
        user={user}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        closeModal={closeChangePasswordModal}
        user={user}
      />
      <Container>
        <Descriptions
          title={user.name}
          bordered
          labelStyle={{ width: 150 }}
          extra={
            <Button onClick={openEditUserModal} type="primary">
              Edit
            </Button>
          }
        >
          <Descriptions.Item span={3} label="Id">
            {user.id}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Email">
            {user.email}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Last Login">
            {user.lastLogin && formatFullDate(user.lastLogin)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Created at">
            {formatFullDate(user.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Email Verified">
            {user.verified ? 'YES' : 'NO'}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="About">
            {user.about}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Team/Company">
            {user.teamOrCompany}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Status">
            {AccountStatusLabels[user.status]}
            {user.status === AccountStatus.Init && (
              <Button
                onClick={approveUser}
                style={{ marginLeft: 16 }}
                type="ghost"
                loading={userApproveLoading}
              >
                Approve
              </Button>
            )}
            {user.status === AccountStatus.Approved && (
              <Button
                onClick={freezeUser}
                style={{ marginLeft: 16 }}
                type="ghost"
                loading={userApproveLoading}
              >
                Freeze
              </Button>
            )}
            {user.status === AccountStatus.Freezed && (
              <Button
                onClick={approveUser}
                style={{ marginLeft: 16 }}
                type="ghost"
                loading={userApproveLoading}
              >
                Enable
              </Button>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Button
          style={{ marginTop: 32, marginBottom: 16 }}
          onClick={openCreateBoatModal}
          type="primary"
        >
          Add a new boat
        </Button>
        <Button
          style={{ marginTop: 32, marginBottom: 16, marginLeft: 16 }}
          onClick={openChangePasswordModal}
          type="primary"
        >
          Change user password
        </Button>
        {boats.map((boat) => (
          <Descriptions
            labelStyle={{ width: 150 }}
            key={boat.id}
            style={{ marginTop: 32 }}
            title={`${boat.name} boat files`}
            bordered
            extra={
              <>
                <Popconfirm
                  placement="topRight"
                  title="Are you sure you want to delete?"
                  onConfirm={deleteSelectedBoat}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    onClick={() => setDeleteId(boat.id)}
                    type="primary"
                    danger
                    style={{ marginRight: 16 }}
                  >
                    Delete
                  </Button>
                </Popconfirm>
                <Button onClick={() => setEditedBoat(boat)} type="primary">
                  Edit
                </Button>
              </>
            }
          >
            <Descriptions.Item span={3} label="Id">
              {boat.id}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Polars">
              {boat.polarsFile && (
                <DownloadLink
                  id={boat.polarsFile.id}
                  name={boat.polarsFile?.fileName}
                />
              )}
              {' - '}
              {boat.polarsFile?.timeStamp &&
                formatFullDate(boat.polarsFile?.timeStamp)}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Sails">
              {boat.sailsFile && (
                <DownloadLink
                  id={boat.sailsFile.id}
                  name={boat.sailsFile?.fileName}
                />
              )}
              {' - '}
              {boat.sailsFile?.timeStamp &&
                formatFullDate(boat.sailsFile?.timeStamp)}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Wave">
              {boat.waveFile && (
                <DownloadLink
                  id={boat.waveFile.id}
                  name={boat.waveFile?.fileName}
                />
              )}
              {' - '}
              {boat.waveFile?.timeStamp &&
                formatFullDate(boat.waveFile?.timeStamp)}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </Container>
    </>
  );
};

export default memo(UserDetails);
