import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { User } from '../types';

import { setUsers, setUser } from './actions';

const storage = createReducer({} as { [key: number]: User }, (builder) => {
  builder
    .addCase(setUsers, (state, action) => ({
      ...state,
      ...Object.fromEntries(action.payload.map((user) => [user.id, user])),
    }))
    .addCase(setUser, (state, action) => {
      state[action.payload.id] = action.payload;
      return state;
    });
});

const hasMore = createReducer(true, (builder) => {
  builder.addCase(setUsers, () => false);
});

export const usersReducer = combineReducers({
  storage,
  hasMore,
});
