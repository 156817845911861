import React, { memo } from 'react';
import { Button } from 'antd';

import { useGetUsersCsv } from 'users/hooks';

const DownloadUserCsvUi: React.FC = () => {
  const { csvDownloading, handleDownloadCsv } = useGetUsersCsv();
  return (
    <Button loading={csvDownloading} onClick={handleDownloadCsv}>
      Download Csv
    </Button>
  );
};

export const DownloadUserCsv = memo(DownloadUserCsvUi);
