import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UsersSelectors, UsersActions } from '../redux';
import { UsersApi } from '../api';
import { User } from '../types';

export const useGetUser = (
  id: number,
): {
  error: boolean;
  loading: boolean;
  fetchUser: () => Promise<void>;
  user: User | undefined;
} => {
  const dispatch = useDispatch();
  const user = useSelector(UsersSelectors.userByIdSelectorFactory(id));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchUser = useCallback(async () => {
    try {
      if (user) return;

      setLoading(true);
      const responseUser = await UsersApi.getUser(id);
      dispatch(UsersActions.setUser(responseUser));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, []);

  return {
    error,
    loading,
    fetchUser,
    user,
  };
};
