import React, { memo } from 'react';

import { NoFooterModal } from 'modal';
import { Boat } from 'boats/types';
import { useUpdateBoat } from 'boats';

import { BoatForm } from '../BoatForm';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  boat: Boat;
  onSuccess?: () => void;
};

const EditBoatModalUi: React.FC<Props> = ({
  isOpen,
  closeModal,
  boat,
  onSuccess,
}) => {
  const {
    polarsFile,
    sailsFile,
    waveFile,
    setPolarFile,
    setSailsFile,
    setWaveFile,
    updateBoatFiles,
    valid,
    loading,
    resetFiles,
    setName,
    name,
  } = useUpdateBoat(boat, onSuccess);

  return (
    <NoFooterModal
      isOpen={isOpen}
      closeModal={closeModal}
      title={`Boat ${boat.name}`}
      loading={loading}
      afterClose={resetFiles}
    >
      {boat ? (
        <BoatForm
          loading={loading}
          name={name}
          setName={setName}
          setPolarFile={setPolarFile}
          polarsFile={
            polarsFile ||
            (boat.polarsFile &&
              new File([], boat.polarsFile.fileName, {
                lastModified: new Date(boat.polarsFile.timeStamp).getTime(),
              }))
          }
          setSailsFile={setSailsFile}
          sailsFile={
            sailsFile ||
            (boat.sailsFile &&
              new File([], boat.sailsFile.fileName, {
                lastModified: new Date(boat.sailsFile.timeStamp).getTime(),
              }))
          }
          setWaveFile={setWaveFile}
          waveFile={
            waveFile ||
            (boat.waveFile &&
              new File([], boat.waveFile.fileName, {
                lastModified: new Date(boat.waveFile.timeStamp).getTime(),
              }))
          }
          onButtonPress={updateBoatFiles}
          valid={valid}
          buttonLabel="Update"
        />
      ) : null}
    </NoFooterModal>
  );
};

export const EditBoatModal = memo(EditBoatModalUi);
