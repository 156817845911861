import React, { memo } from 'react';

import { NoFooterModal } from 'modal';
import { useCreateBoat } from '../../hooks';

import { BoatForm } from '../BoatForm';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  onSuccess?: () => void;
  userId: number;
};

const CreatBoatModalUi: React.FC<Props> = ({
  isOpen,
  closeModal,
  userId,
  onSuccess,
}) => {
  const {
    polarsFile,
    sailsFile,
    waveFile,
    setPolarFile,
    setSailsFile,
    setWaveFile,
    updateBoatFiles,
    valid,
    loading,
    resetFiles,
    setName,
    name,
  } = useCreateBoat(userId, onSuccess);

  return (
    <NoFooterModal
      isOpen={isOpen}
      closeModal={closeModal}
      title="New boat"
      loading={loading}
      afterClose={resetFiles}
    >
      <BoatForm
        loading={loading}
        name={name}
        setName={setName}
        setPolarFile={setPolarFile}
        polarsFile={polarsFile}
        setSailsFile={setSailsFile}
        sailsFile={sailsFile}
        setWaveFile={setWaveFile}
        waveFile={waveFile}
        onButtonPress={updateBoatFiles}
        valid={valid}
        buttonLabel="Create"
      />
    </NoFooterModal>
  );
};

export const CreatBoatModal = memo(CreatBoatModalUi);
