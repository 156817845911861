import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { UsersActions } from '../redux';
import { UsersApi } from '../api';
import { User } from '../types';

export const useEditUser = (
  onSuccess: () => void,
): {
  error: boolean;
  loading: boolean;
  updateUser: (user: User) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateUser = useCallback(async (user: User) => {
    try {
      if (!user) return;
      setLoading(true);
      const responseUser = await UsersApi.updateUser({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        about: user.about,
        teamOrCompany: user.teamOrCompany,
      });
      dispatch(UsersActions.setUser(responseUser));
      onSuccess();
      message.success('User data updated');
    } catch (error) {
      setError(true);
      message.error('Error while updating user info');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    error,
    loading,
    updateUser,
  };
};
