import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';

import { useRefreshToken } from 'auth';
import { UsersSwitch } from 'users';
import { LogsSwitch } from 'logs';
import { SideMenu } from 'sideMenu';
import { Routes } from 'Routes';

const DashboardRouter: React.FC = () => {
  const { initialized } = useRefreshToken();

  if (!initialized) return null;

  return (
    <Layout>
      <Layout.Sider width={256}>
        <SideMenu />
      </Layout.Sider>
      <Layout.Content
        style={{ padding: 64, overflow: 'auto', height: '100vh' }}
      >
        <Switch>
          <Route
            path={[Routes.Users, Routes.UserDetails]}
            component={UsersSwitch}
          />
          <Route path={[Routes.Logs]} component={LogsSwitch} />
          <Route component={UsersSwitch} />
        </Switch>
      </Layout.Content>
    </Layout>
  );
};

export default React.memo(DashboardRouter);
