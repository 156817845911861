export enum AccountStatus {
  Init = 1,
  Approved,
  Freezed,
}

export const AccountStatusLabels = {
  [AccountStatus.Init]: 'Waiting for approval',
  [AccountStatus.Approved]: 'Approved',
  [AccountStatus.Freezed]: 'Freezed',
};
