import { Button } from 'antd';
import React from 'react';

import { TextInput, DragAndDrop } from 'ui';

import { ButtonContainer } from './styledComponents';

type Props = {
  setName: (name: string) => void;
  name: string;
  polarsFile: File | null;
  setPolarFile: (file: File) => void;
  sailsFile: File | null;
  setSailsFile: (file: File) => void;
  waveFile: File | null;
  setWaveFile: (file: File) => void;
  onButtonPress: () => Promise<void>;
  valid: boolean;
  loading: boolean;
  buttonLabel: string;
};

export const BoatForm: React.FC<Props> = ({
  setName,
  name,
  setPolarFile,
  polarsFile,
  sailsFile,
  setSailsFile,
  setWaveFile,
  waveFile,
  onButtonPress,
  valid,
  buttonLabel,
  loading,
}) => (
  <>
    <TextInput label="Name" onChange={setName} value={name} />
    <DragAndDrop onChange={setPolarFile} label="Polars" file={polarsFile} />
    <DragAndDrop onChange={setSailsFile} label="Sails" file={sailsFile} />
    <DragAndDrop onChange={setWaveFile} label="Wave" file={waveFile} />
    <ButtonContainer>
      <Button
        onClick={onButtonPress}
        disabled={!valid}
        type="primary"
        loading={loading}
      >
        {buttonLabel}
      </Button>
    </ButtonContainer>
  </>
);
