import { useState, useCallback } from 'react';

import { Boat } from 'boats/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBoatForm = (boat: Boat | undefined) => {
  const [name, setName] = useState(boat?.name || '');
  const [polarsFile, setPolarFile] = useState<File | null>(null);
  const [sailsFile, setSailsFile] = useState<File | null>(null);
  const [waveFile, setWaveFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const resetFiles = useCallback(async () => {
    setPolarFile(null);
    setSailsFile(null);
    setWaveFile(null);
    setName('');
  }, []);

  return {
    error,
    loading,
    polarsFile,
    setPolarFile,
    sailsFile,
    setSailsFile,
    waveFile,
    setWaveFile,
    resetFiles,
    name,
    setName,
    setLoading,
    setError,
  };
};
