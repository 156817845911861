import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';

import { LoginPage } from './pages';

export const AuthSwitch: React.FC = memo(() => {
  return (
    <Switch>
      <Route component={LoginPage} />
    </Switch>
  );
});
