import React, { memo } from 'react';

import { useGetUsers } from '../../hooks/useGetUsers';
import { DownloadUserCsv, UserTable } from '../../components';

import { ButtonContainer } from './styledComponents';

const UsersListPage: React.FC = () => {
  const { users, loading } = useGetUsers();
  return (
    <>
      <ButtonContainer>
        <DownloadUserCsv />
      </ButtonContainer>
      <UserTable users={users} loading={loading} />
    </>
  );
};

export default memo(UsersListPage);
