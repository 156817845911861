import { useState, useCallback, useEffect } from 'react';

import { BoatsApi } from '../api';
import { Boat } from '../types';

export const useGetUserBoats = (
  userId: number,
): {
  boats: Boat[];
  error: boolean;
  loading: boolean;
  fetchBoats: () => Promise<void>;
} => {
  const [boats, setBoats] = useState<Boat[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchBoats = useCallback(async () => {
    try {
      setLoading(true);
      const responseBoats = await BoatsApi.getBoats(userId);
      setBoats(responseBoats);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBoats();
  }, []);

  return {
    boats,
    error,
    loading,
    fetchBoats,
  };
};
