import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 480px;
  flex-direction: column;
  & > input {
    margin-bottom: 32px;
  }
  & > span {
    margin-bottom: 32px;
  }
  & > button {
    width: 300px;
  }
`;
