import React, { memo, useCallback } from 'react';
import { message } from 'antd';
import { FileOutlined } from '@ant-design/icons';

import { FilesApi } from '../../api';
import { StyledLink } from './styledComponents';

type Props = {
  id: number;
  name: string;
};

const DownloadLinkUi: React.FC<Props> = ({ name, id }) => {
  const handleDownload = useCallback(async () => {
    const link = await FilesApi.getFileLink(id);
    if (!link) {
      message.error('Unable to download');
      return;
    }

    const linkElement = document.createElement('a');
    linkElement.href = link;
    linkElement.click();
    linkElement.remove();
  }, []);

  return (
    <StyledLink onClick={handleDownload} role="link">
      {name}
      <FileOutlined />
    </StyledLink>
  );
};

export const DownloadLink = memo(DownloadLinkUi);
