import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { AuthRoutes } from 'Routes';
import { AuthSwitch, AuthRoute, PrivateRoute } from 'auth';
import Dashboard from './Dashboard';

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <AuthRoute path={AuthRoutes} component={AuthSwitch} />
        <PrivateRoute component={Dashboard} />
      </Switch>
    </Router>
  );
};

export default App;
