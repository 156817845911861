import { useCallback, useState } from 'react';
import { message } from 'antd';

import { downloadBlobFile } from 'file';
import { UsersApi } from '../api';

export const useGetUsersCsv = (): {
  handleDownloadCsv: () => Promise<void>;
  csvDownloading: boolean;
} => {
  const [csvDownloading, setCsvDownloading] = useState(false);
  const handleDownloadCsv = useCallback(async () => {
    try {
      setCsvDownloading(true);
      const blob = await UsersApi.getUsersCsv();
      downloadBlobFile(blob, 'users.csv');
    } catch (error) {
      message.error('Error while download users CSV');
    } finally {
      setCsvDownloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    handleDownloadCsv,
    csvDownloading,
  };
};
