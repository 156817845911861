import styled from 'styled-components';

import { HighlightedSm } from '../../typography';
import { Colors } from '../../theme';

export const Label = styled(HighlightedSm)<{ invalid: boolean }>`
  margin: 3px 0px;
  color: ${({ invalid }): string | undefined =>
    invalid ? Colors.Destructive : undefined};
`;
