import { useCallback } from 'react';

import { Boat } from 'boats/types';
import { BoatsApi } from '../api';
import { useBoatForm } from './useBoatForm';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpdateBoat = (
  boat: Boat,
  onSuccess: undefined | (() => void),
) => {
  const {
    error,
    loading,
    name,
    polarsFile,
    resetFiles,
    sailsFile,
    setError,
    setLoading,
    setName,
    setPolarFile,
    setSailsFile,
    setWaveFile,
    waveFile,
  } = useBoatForm(boat);

  const updateBoatFiles = useCallback(async () => {
    try {
      setLoading(true);
      await BoatsApi.updateBoatFiles({
        id: boat.id,
        name,
        files: {
          sails: sailsFile,
          polars: polarsFile,
          wave: waveFile,
        },
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [sailsFile, boat.id, polarsFile, waveFile, onSuccess, name]);

  const valid = !!(
    polarsFile ||
    sailsFile ||
    waveFile ||
    (name && name !== boat.name)
  );

  return {
    error,
    loading,
    updateBoatFiles,
    polarsFile,
    setPolarFile,
    sailsFile,
    setSailsFile,
    waveFile,
    setWaveFile,
    valid,
    resetFiles,
    name,
    setName,
  };
};
